export const appV2EventPrefix = "HCP Mobile App (V2):";

export const APP_V2_USER_EVENTS = {
  // Auth
  SUBMIT_LOGIN_FORM_WITH_PHONE_NUMBER: `${appV2EventPrefix} Submit Login Form With Phone Number`,
  SUBMIT_LOGIN_FORM_WITH_EMAIL: `${appV2EventPrefix} Submit Login Form With Email`,
  SWITCH_LOGIN_METHOD: `${appV2EventPrefix} Switch Login Method`,
  SUBMIT_SIGNUP_FORM: `${appV2EventPrefix} Submit Signup Form`,
  SWITCH_BETWEEN_LOGIN_SIGNUP: `${appV2EventPrefix} Switch Between Login and Signup`,
  SUBMIT_AUTH_OTP_FORM: `${appV2EventPrefix} Submit OTP form`,
  SUBMIT_AUTH_OTP_FORM_SUCCESS: `${appV2EventPrefix} Submit OTP form success`,
  SUBMIT_AUTH_OTP_FORM_ERROR: `${appV2EventPrefix} Submit OTP form error`,
  RESEND_OTP: `${appV2EventPrefix} Resend OTP`,
  CLOSE_VERIFY_OTP_DIALOG: `${appV2EventPrefix} Close OTP form`,
  CLOSE_LOGIN_CONFIRMATION_DIALOG: `${appV2EventPrefix} Close Login Confirmation Dialog`,
  SELECT_PHONE_NUMBER_EXISTS_LOGIN: `${appV2EventPrefix} Phone Number Exists Login`,
  CLOSE_PHONE_NUMBER_EXISTS_DIALOG: `${appV2EventPrefix} Close Phone Number Exists Dialog`,
  SELECT_SIGN_UP_INSTEAD: `${appV2EventPrefix} Sign Up Instead`,
  CLOSE_SIGN_UP_INSTEAD_DIALOG: `${appV2EventPrefix} Close Sign Up Instead Dialog`,
  VIEWED_WHATS_NEXT: `${appV2EventPrefix} Viewed What's Next information`,

  // Rating
  IN_APP_RATING_PROMPT_REQUESTED: `${appV2EventPrefix} In-app rating prompt requested`,
  IN_APP_RATING_PROMPT_TRIGGERED: `${appV2EventPrefix} In-app rating prompt triggered`,
  IN_APP_RATING_PROMPT_NOT_TRIGGERED: `${appV2EventPrefix} In-app rating prompt not triggered`,
  IN_APP_RATING_PROMPT_ERROR: `${appV2EventPrefix} In-app rating prompt error`,
  IN_APP_RATING_PROMPT_FETCH_PAYROLL: `${appV2EventPrefix} In-app rating prompt caused fetch payroll`,

  // Open Shifts
  OPEN_SHIFTS_CALENDAR_VIEWED: `${appV2EventPrefix} Open Shift Calendar Viewed`,
  OPEN_SHIFTS_CALENDAR_DATE_SELECTED: `${appV2EventPrefix} Open Shift Calendar Date Selected`,
  OPEN_SHIFTS_CALENDAR_SELECT_PREVIOUS: `${appV2EventPrefix} Open Shift Calendar Select Previous`,
  OPEN_SHIFTS_CALENDAR_SELECT_NEXT: `${appV2EventPrefix} Open Shift Calendar Select Next`,
  OPEN_SHIFTS_CALENDAR_OPEN_FILTER_LICENSE: `${appV2EventPrefix} Open Shift Calendar Open Filter License`,
  OPEN_SHIFTS_CALENDAR_OPEN_FILTER_DISTANCE: `${appV2EventPrefix} Open Shift Calendar Open Filter Distance`,
  OPEN_SHIFTS_CALENDAR_SUBMIT_FILTER_DISTANCE_DIALOG: `${appV2EventPrefix} Open Shift Calendar Submit Filter Distance`,
  OPEN_SHIFTS_CALENDAR_SUBMIT_FILTER_LICENSE_DIALOG: `${appV2EventPrefix} Open Shift Calendar Submit Filter License`,
  OPEN_SHIFT_COLLEAGUES_VIEWED: `${appV2EventPrefix} Open shift colleagues viewed`,
  OPEN_SHIFTS_DAY_VIEW_VIEWED: `${appV2EventPrefix} Open Shifts Day View V2 Viewed`,
  OPEN_SHIFTS_DAY_VIEW_SHIFT_PERIOD_SELECTED: `${appV2EventPrefix} Open Shift Day View V2 Shift Period Selected`,
  VIEWED_NEXT_OPEN_SHIFT_PAGE: `${appV2EventPrefix} Viewed Next Open Shifts Page`,

  // Shift Discovery
  OPEN_SHIFTS_CALENDAR_OPENED: `${appV2EventPrefix} Open Shifts calendar Opened`,
  OPEN_SHIFTS_CALENDAR_CLOSED: `${appV2EventPrefix} Open Shifts Calendar Closed`,
  OPEN_SHIFTS_CALENDAR_VIEWED_SELECTED: `${appV2EventPrefix} Open Shifts Calendar Viewed Selected Dates`,
  OPEN_SHIFTS_CALENDAR_DATE_TOGGLED: `${appV2EventPrefix} Open Shifts Calendar Date Toggled`,
  OPEN_SHIFTS_CALENDAR_DATES_CLEARED: `${appV2EventPrefix} Open Shifts Calendar Dates Cleared`,
  OPEN_SHIFTS_FILTERS_VIEWED: `${appV2EventPrefix} Open Shifts Filters Viewed`,
  OPEN_SHIFTS_FILTERS_APPLIED: `${appV2EventPrefix} Open Shifts Filters Applied`,
  OPEN_SHIFTS_FILTERS_CLOSED: `${appV2EventPrefix} Open Shifts Filters Closed`,
  OPEN_SHIFTS_FILTERS_CLEARED: `${appV2EventPrefix} Open Shifts Filters Cleared`,
  OPEN_SHIFTS_CALLOUT_VIEWED: `${appV2EventPrefix} Open Shifts Callout Viewed`,
  SHIFT_DETAILS_OPENED: `${appV2EventPrefix} Shift Details Opened`,
  SHIFT_DETAILS_VIEWED: `${appV2EventPrefix} Shift Details Viewed`,
  SHIFT_DETAILS_CLOSED: `${appV2EventPrefix} Shift Details Closed`,
  SHIFT_DETAILS_FAILED_TO_LOAD: `${appV2EventPrefix} Shift Details Failed To Load`,
  SHIFT_DETAILS_UNAVAILABLE_CLOSED: `${appV2EventPrefix} Shift Details Unavailable Closed`,
  SHIFT_DETAILS_COPY_WORKPLACE_ADDRESS: `${appV2EventPrefix} Shift Details Copy Workplace Address`,
  SHIFT_DETAILS_OPEN_WORKPLACE_MAP: `${appV2EventPrefix} Shift Details Open Workplace Map`,
  SHIFT_DETAILS_CTA_BOOK_SHIFT: `${appV2EventPrefix} Shift Details CTA Book Shift`,
  SHIFT_DETAILS_CTA_REGISTER_ON_CALL_SHIFT: `${appV2EventPrefix} Shift Details CTA Register On-Call Shift`,
  SHIFT_DETAILS_CTA_CONFIRM_REGISTER_ON_CALL_SHIFT: `${appV2EventPrefix} Shift Details CTA Confirm Register On-Call Shift`,
  SHIFT_DETAILS_CTA_VIEW_REQUIREMENTS: `${appV2EventPrefix} Shift Details CTA View Requirements`,
  SHIFT_DETAILS_CARD_CTA_BLOCKING_DOCUMENT: `${appV2EventPrefix} Shift Details Card CTA Blocking Document`,
  SHIFT_DETAILS_CTA_START_QUIZ: `${appV2EventPrefix} Shift Details CTA Start Quiz`,
  SHIFT_DETAILS_CTA_SIGN_AGREEMENT: `${appV2EventPrefix} Shift Details CTA Sign Agreement`,
  SHIFT_DETAILS_CTA_SETUP_PAYMENTS: `${appV2EventPrefix} Shift Details CTA Setup Payments`,
  SHIFT_DETAILS_CTA_MARK_INTEREST: `${appV2EventPrefix} Shift Details CTA Mark Interest`,
  SHIFT_DETAILS_CTA_CREATE_BOOKING_REQUEST: `${appV2EventPrefix} Shift Details CTA Create Booking Request`,
  SHIFT_DETAILS_NOT_INSTANT_BOOKABLE: `${appV2EventPrefix} Shift Details Not Instant Bookable`,
  WORKPLACE_CHECK_IN_INSTRUCTIONS_OPENED: `${appV2EventPrefix} Workplace Check In Instructions Opened`,
  WORKPLACE_OPEN_SHIFTS_OPENED: `${appV2EventPrefix} Workplace Open Shifts Opened`,
  SHIFTS_INVALIDATED_ON_DEEPLINK: `${appV2EventPrefix} Shifts Invalidated On Deeplink`,
  SHIFTS_INVALIDATED_ON_APP_RESUMED: `${appV2EventPrefix} Shifts Invalidated On App Resumed`,
  SHIFT_LIST_TIME_SLOT_CLICKED: `${appV2EventPrefix} Shift List Time Slot Clicked`,
  BROWSE_WORKPLACES_OPENED: `${appV2EventPrefix} Browse Workplaces Opened`,
  BROWSE_WORKPLACES_CLOSED: `${appV2EventPrefix} Browse Workplaces Closed`,
  URGENT_SHIFTS_DEEPLINK_OPENED: `${appV2EventPrefix} Urgent Shifts Deeplink Opened`,
  URGENT_SHIFTS_DEEPLINK_SHIFT_OPENED: `${appV2EventPrefix} Urgent Shifts Deeplink Shift Opened`,
  URGENT_SHIFTS_DEEPLINK_MISSING_SHIFT: `${appV2EventPrefix} Urgent Shifts Deeplink Missing Shift`,
  OFFER_CREATED: `${appV2EventPrefix} Offer Created`,

  // Shift Details
  SHIFT_DETAILS_CLOCK_IN_NOW_TAPPED: `${appV2EventPrefix} Shift Details Clock In Now Tapped`,
  SHIFT_DETAILS_LATE_FOR_SHIFT_TAPPED: `${appV2EventPrefix} Shift Details Late For Shift Tapped`,
  SHIFT_DETAILS_SENT_HOME_TAPPED: `${appV2EventPrefix} Shift Details Sent Home Tapped`,
  SHIFT_DETAILS_CANCEL_SHIFT_TAPPED: `${appV2EventPrefix} Shift Details Cancel Shift Tapped`,

  // Shift Actions
  INSTANT_BOOKED_SHIFT: `${appV2EventPrefix} Instant Booked Shift`,
  MARKED_INTEREST_SHIFT: `${appV2EventPrefix} Marked Interest Shift`,
  UNDO_BOOKING: `${appV2EventPrefix} Undo Booking`,

  // Shift booking document submission flows
  SHIFT_BOOKING_FLOW_DOCUMENT_SUBMISSION_REVIEW_CTA_CLICKED: `${appV2EventPrefix} Shift documents flow document submission review cta clicked`,
  SHIFT_BOOKING_FLOW_DOCUMENT_SUBMISSION_SUCCESSFUL: `${appV2EventPrefix} Shift documents flow document submission successful`,
  SHIFT_BOOKING_FLOW_DOCUMENT_SUBMISSION_FAILED: `${appV2EventPrefix} Shift documents flow document submission failed`,

  // Worker Availability
  AVAILABILITY_CALENDAR_VIEWED: `${appV2EventPrefix} Availability Calendar Viewed`,

  // Reviews
  WORKPLACE_REVIEWS_RATING_SUBMITTED: `${appV2EventPrefix} Workplace Reviews Rating Submitted`,
  WORKPLACE_REVIEWS_RATING_UPDATED: `${appV2EventPrefix} Workplace Reviews Rating Updated`,
  WORKPLACE_REVIEWS_REVIEW_SUBMITTED: `${appV2EventPrefix} Workplace Reviews Review Submitted`,
  WORKPLACE_REVIEWS_SCREEN_OPENED: `${appV2EventPrefix} Workplace Reviews Screen Opened`,
  WORKPLACE_REVIEWS_SCREEN_CLOSED: `${appV2EventPrefix} Workplace Reviews Screen Closed`,
  WORKPLACE_REVIEWS_OPENED_FROM_FEEDBACK_MODAL: `${appV2EventPrefix} Workplace Reviews Screen Opened From Feedback Modal`,

  // Refer and earn intermediate screen
  REFER_AND_EARN: `${appV2EventPrefix} Refer and Earn`,

  // Workplace referral events
  WORKPLACE_REFERRAL: `${appV2EventPrefix} Workplace Referral`,
  WORKPLACE_REFERRAL_STATS: `${appV2EventPrefix} Workplace Referral Stats`,

  // Support
  OPENED_SUPPORT_ARTICLE: `${appV2EventPrefix} Opened Support Article`,

  // Worker to Worker Referrals events
  VIEWED_WORKER_TO_WORKER_REFERRAL_SCREEN: `${appV2EventPrefix} Viewed worker to worker referral screen`,
  SHARED_CODE_FROM_WORKER_TO_WORKER_REFERRAL_SCREEN: `${appV2EventPrefix} Shared code from worker to worker referral screen`,
  VISITED_ENTER_REFERRAL_CODE_FROM_WORKER_TO_WORKER_REFERRAL_SCREEN: `${appV2EventPrefix} Visited enter referral code screen from worker to worker referral screen`,
  CLICKED_ON_WORKER_TO_WORKER_REFERRAL_BANNER: `${appV2EventPrefix} Clicked on worker to worker referral banner`,

  // My Shifts
  SUBMITTED_ETA_MODAL: `${appV2EventPrefix} Submitted ETA modal`,
  VIEWED_FACILITY_PROFILE: `${appV2EventPrefix} Facility Profile Viewed`,
  SHIFT_CARD_FACILITY_PROFILE_CLICKED: `${appV2EventPrefix} Shift card facility profile clicked`,
  SHIFT_CARD_CLICKED: `${appV2EventPrefix} MyShifts shift card clicked`,
  UNVERIFIED_SHIFTS_CARD_CLICKED: `${appV2EventPrefix} MyShifts unverified shifts card clicked`,
  SHIFT_CARD_CHAT_BUTTON_CLICKED: `${appV2EventPrefix} Shift card facility chat button clicked`,
  MESSAGES_ICON_CLICKED: `${appV2EventPrefix} Messages icon clicked`,
  PENDING_SENT_HOME_REQUESTS_CARD_CLICKED: `${appV2EventPrefix} MyShifts pending sent home requests card clicked`,
  NEW_SIGNING_FLOW_WITH_FEEDBACK_MODAL_OPENED: `${appV2EventPrefix} New signing flow with feedback modal opened`,

  // On Call
  ON_CALL_SHIFT_ALL_OFFERS_REJECTED: `${appV2EventPrefix} On call shift all offers rejected`,
  ON_CALL_SHIFT_OFFER_ACCEPTED: `${appV2EventPrefix} On call shift offer accepted`,

  // Booking Requests
  BOOKING_REQUEST_CANCELED: `${appV2EventPrefix} Booking request canceled`,

  // Documents
  UPLOAD_DOCUMENT_SUCCESS: `${appV2EventPrefix} Successfully uploaded document`,
  UPLOAD_DOCUMENT_FAILURE: `${appV2EventPrefix} Failed to upload document`,
  DOCUMENT_DETAILS_SCREEN_VIEWED: `${appV2EventPrefix} Document Details Screen Viewed`,
  VIEWED_DOCUMENTS: `${appV2EventPrefix} Documents Viewed`,
  MISSING_DOCUMENTS_ALERT_UPLOAD_BUTTON_CLICKED: `${appV2EventPrefix} Missing documents alert upload button clicked`,
  MISSING_DOCUMENTS_NOTIFY_SUPPORT_BUTTON_CLICKED: `${appV2EventPrefix} Missing documents alert notify support button clicked`,
  DOCUMENTS_PAGE_MORE_INFO_ICON_CLICKED: `${appV2EventPrefix} Documents page more info icon clicked`,

  // Documents upload flow
  DOCUMENTS_UPLOAD_FLOW_VIEWED_MISSING_DOCUMENTS_ALERT: `${appV2EventPrefix} Documents upload flow viewed missing docs alert`,
  DOCUMENTS_UPLOAD_FLOW_MISSING_DOCUMENTS_ALERT_CLICKED_VIEW_DOCUMENTS: `${appV2EventPrefix} Documents upload flow missing docs alert clicked view documents`,
  DOCUMENTS_UPLOAD_FLOW_DOCUMENTS_PAGE_VIEWED: `${appV2EventPrefix} Documents upload flow documents page viewed`,
  DOCUMENTS_UPLOAD_FLOW_DOCUMENTS_PAGE_REQUIREMENT_CLICKED: `${appV2EventPrefix} Documents upload flow documents page requirement clicked`,
  DOCUMENTS_UPLOAD_FLOW_DOCUMENTS_DETAILS_PAGE_VIEWED: `${appV2EventPrefix} Documents upload flow documents details page viewed`,
  DOCUMENTS_UPLOAD_FLOW_DOCUMENT_DETAILS_CTA_CLICKED: `${appV2EventPrefix} Documents upload flow document details cta clicked`,
  DOCUMENTS_UPLOAD_FLOW_DOCUMENT_DETAILS_SUBMISSION_FINISHED: `${appV2EventPrefix} Documents upload flow document details upload finished`,

  // Notifications
  NOTIFICATION_CENTER_ICON_VIEWED: `${appV2EventPrefix} Notification center icon viewed`,
  NOTIFICATION_CENTER_VIEWED: `${appV2EventPrefix} Notification center viewed`,

  // Urgent Shifts
  TAPPED_URGENT_SHIFTS_BANNER: `${appV2EventPrefix} Tapped Urgent Shifts Banner`,

  // Hot shifts
  HOT_SHIFT_ICON_TAPPED: `${appV2EventPrefix} Hot Shift Icon Tapped`,

  // Shift bookability
  TAPPED_CONTACT_SUPPORT_FOR_UNDETERMINED_BOOKABILITY_STATUS: `${appV2EventPrefix} Tapped Contact Support For Undetermined Bookability Status`,
  TAPPED_SHIFT_UNAVAILABLE_FOR_BLOCKED_BOOKABILITY_STATUS: `${appV2EventPrefix} Tapped Shift Unavailable For Blocked Bookability Status`,
  TAPPED_BUTTON_FOR_BLOCKED_BOOKABILITY_STATUS: `${appV2EventPrefix} Tapped Button For Blocked Bookability Status`,
  TAPPED_BUTTON_FOR_ALLOWED_BOOKABILITY_STATUS: `${appV2EventPrefix} Tapped Button For Allowed Bookability Status`,
  VIEWED_MISSING_DOCUMENTS_ALERT: `${appV2EventPrefix} Viewed missing documents alert`,
  VIEWED_UNDETERMINED_SHIFT_BUTTON: `${appV2EventPrefix} Viewed undetermined shift button`,
  VIEWED_CANT_BOOK_SHIFT_CTA: `${appV2EventPrefix} Viewed can't book shift button`,

  // Onboarding
  STARTED_STRIPE_SETUP: `${appV2EventPrefix} Started Stripe Setup`,
  VIEWED_STRIPE_SETUP: `${appV2EventPrefix} Viewed Stripe Setup`,

  // Mandatory break policy
  MANDATORY_BREAK_POLICY_VIEWED: `${appV2EventPrefix} Mandatory break policy dialog viewed`,
  MANDATORY_BREAK_POLICY_CANCELLED: `${appV2EventPrefix} Mandatory break policy dialog cancelled`,
  MANDATORY_BREAK_POLICY_ACCEPTED: `${appV2EventPrefix} Mandatory break policy dialog accepted`,

  // WorkplaceQuiz
  VIEWED_QUIZ_PASSED_DIALOG: `${appV2EventPrefix} Viewed quiz passed dialog`,
  VIEWED_QUIZ_FAILED_DIALOG: `${appV2EventPrefix} Viewed quiz failed dialog`,
  TAPPED_BUTTON_FOR_BOOK_SHIFT_ON_QUIZ_PASSED_WITH_SHIFT_DIALOG: `${appV2EventPrefix} Tapped button for book shift on quiz passed with shift dialog`,
  TAPPED_BUTTON_FOR_CANCEL_ON_QUIZ_PASSED_WITH_SHIFT_DIALOG: `${appV2EventPrefix} Tapped button for cancel on quiz passed with shift dialog`,
  TAPPED_BUTTON_FOR_RETAKE_QUIZ_ON_QUIZ_FAILED_DIALOG: `${appV2EventPrefix} Tapped button for retake quiz on quiz failed dialog`,
  TAPPED_BUTTON_FOR_CANCEL_ON_QUIZ_FAILED_DIALOG: `${appV2EventPrefix} Tapped button for cancel on quiz failed dialog`,
  VIEWED_QUIZ_REQUIREMENT_DIALOG: `${appV2EventPrefix} Viewed quiz requirement dialog`,
  TAPPED_BUTTON_FOR_CONTINUE_ON_QUIZ_REQUIREMENT_DIALOG: `${appV2EventPrefix} Tapped button for continue on quiz requirement dialog`,
  VIEWED_WORKPLACE_RULES_PAGE: `${appV2EventPrefix} Viewed workplace rules page`,
  CLOSED_WORKPLACE_RULES_PAGE: `${appV2EventPrefix} Closed workplace rules page`,
  VIEWED_WORKPLACE_QUIZ_PAGE: `${appV2EventPrefix} Viewed workplace quiz page`,
  CLOSED_WORKPLACE_QUIZ_PAGE: `${appV2EventPrefix} Closed workplace quiz page`,

  // Skills Assessment
  SKILLS_ASSESSMENT_MY_ACCOUNT_BANNER_CLICKED: `${appV2EventPrefix} Skills assessment my account banner clicked`,
  SKILLS_ASSESSMENT_MY_ACCOUNT_PAGE_VIEWED: `${appV2EventPrefix} Skills assessment page viewed in my account`,
  SKILLS_ASSESSMENT_ONBOARDING_PAGE_VIEWED: `${appV2EventPrefix} Skills assessment page viewed during onboarding`,
  SKILLS_ASSESSMENT_SCHEDULED_ONBOARDING_INSTRUCTIONS_PAGE_VIEWED: `${appV2EventPrefix} Skills assessment instructions page viewed during onboarding for scheduled assessment`,
  SKILLS_ASSESSMENT_SCHEDULED_ONBOARDING_PAGE_VIEWED: `${appV2EventPrefix} Skills assessment page viewed during onboarding for scheduled assessment`,
  SKILLS_ASSESSMENT_SCHEDULED_ONBOARDING_SCHEDULE_BUTTON_CLICKED: `${appV2EventPrefix} Skills assessment schedule button clicked during onboarding for scheduled assessment`,
  SKILLS_ASSESSMENT_SCHEDULED_ONBOARDING_INSTRUCTIONS_BUTTON_CLICKED: `${appV2EventPrefix} Skills assessment instructions button clicked during onboarding for scheduled assessment`,
  SKILLS_ASSESSMENT_SCHEDULED_ONBOARDING_CONFIRMATION_PAGE_VIEWED: `${appV2EventPrefix} Skills assessment confirmation page viewed during onboarding for scheduled assessment`,
  SKILLS_ASSESSMENT_SCHEDULED_ONBOARDING_CONFIRMATION_BUTTON_CLICKED: `${appV2EventPrefix} Skills assessment confirmation button clicked during onboarding for scheduled assessment`,
  SKILLS_ASSESSMENT_ONBOARDING_SKIPPED: `${appV2EventPrefix} Skills assessment onboarding skipped`,
  SKILLS_ASSESSMENT_VIDEO_FLOW_VIEWED: `${appV2EventPrefix} Skills assessment video flow viewed`,
  SKILLS_ASSESSMENT_CLICKED_HAVING_ISSUES: `${appV2EventPrefix} Skills assessment clicked having issues`,
  SKILLS_ASSESSMENT_CLICKED_HAVING_ISSUES_CALL_BUTTON: `${appV2EventPrefix} Skills assessment having issues call button`,
  SKILLS_ASSESSMENT_VIDEO_ASK_STARTED: `${appV2EventPrefix} Skills assessment video ask started`,
  SKILLS_ASSESSMENT_VIDEO_ASK_QUESTION_PRESENTED: `${appV2EventPrefix} Skills assessment video ask question presented`,
  SKILLS_ASSESSMENT_VIDEO_ASK_QUESTION_SUBMITTED: `${appV2EventPrefix} Skills assessment video ask question submitted`,
  SKILLS_ASSESSMENT_VIDEO_ASK_SUBMITTED: `${appV2EventPrefix} Skills assessment video ask submitted`,
  SKILLS_ASSESSMENT_STARTED_EVENT: `${appV2EventPrefix} Skills assessment started event`,
  SKILLS_ASSESSMENT_SCHEDULED_EVENT: `${appV2EventPrefix} Skills assessment scheduled event`,
  SKILLS_ASSESSMENT_RESCHEDULED: `${appV2EventPrefix} Skills assessment rescheduled`,
  SKILLS_ASSESSMENT_SUBMITTED_EVENT: `${appV2EventPrefix} Skills assessment submitted event`,
  SKILLS_ASSESSMENT_VIDEO_ASK_EXITED: `${appV2EventPrefix} Skills assessment video ask exited`,
  SKILLS_ASSESSMENT_EVENT_STATUS_VIEWED: `${appV2EventPrefix} Skills assessment event status viewed`,
  SKILLS_ASSESSMENT_REQUIREMENT_DIALOG_VIEWED: `${appV2EventPrefix} Skills assessment requirement dialog viewed during booking`,
  SKILLS_ASSESSMENT_REQUIREMENT_DIALOG_CONTINUE_BUTTON_CLICKED: `${appV2EventPrefix} Skills assessment continue button clicked on requirement dialog during booking`,
  SKILLS_ASSESSMENT_UNDER_REVIEW_DIALOG_VIEWED: `${appV2EventPrefix} Skills assessment under review dialog viewed during booking`,
  SKILLS_ASSESSMENT_FAILED_DIALOG_VIEWED: `${appV2EventPrefix} Skills assessment failed dialog viewed during booking`,
  SKILLS_ASSESSMENT_UNEXPECTED_ERROR_DIALOG_VIEWED: `${appV2EventPrefix} Skills assessment unexpected error dialog viewed during booking`,
  SKILLS_ASSESSMENT_DIALOG_DETAILS_BUTTON_CLICKED: `${appV2EventPrefix} Skills assessment details button clicked during  booking`,
  SKILLS_ASSESSMENT_RECORDING_PERMISSIONS_CHECKED: `${appV2EventPrefix} Skills assessment recording permissions checked`,
  SKILLS_ASSESSMENT_START_TIME_PASSED_DIALOG_CLOSED: `${appV2EventPrefix} Skills assessment start time passed dialog closed`,
  SKILLS_ASSESSMENT_START_TIME_PASSED_DIALOG_START_NOW_CLICKED: `${appV2EventPrefix} Skills assessment start time passed dialog start now clicked`,
  SKILLS_ASSESSMENTS_RESEND_TEXT_CLICKED: `${appV2EventPrefix} Skills assessments resend text clicked`,
  SKILLS_ASSESSMENT_SKIP_ASSESSMENT_ALERT_CONFIRMED: `${appV2EventPrefix} Skills assessment skip assessment alert confirmed`,

  // Open shifts
  OPEN_SHIFTS_TAB_LAYOUT: `${appV2EventPrefix} Open shifts tab layout`,
  OPENED_SHARED_SHIFT: `${appV2EventPrefix} Opened shared shift`,

  // Shift blocks
  SHIFT_BLOCKS: `${appV2EventPrefix} Shift Blocks`,

  // Streaks
  VIEWED_STREAK_TRACKER_PAGE: `${appV2EventPrefix} Streak tracker page viewed`,

  VIEWED_OPEN_BLOCKS_FROM_STREAK_TRACKER: `${appV2EventPrefix} Open blocks viewed from streak tracker page`,

  // Negotiation
  VIEWED_NEXT_NEGOTIATION_SHIFT_PAGE: `${appV2EventPrefix} Viewed Next Negotiation Shifts Page`,
  RATE_NEGOTIATION_ENABLED_LOGIC_MISMATCH: `${appV2EventPrefix} Rate negotiation enabled logic mismatch`,
  RATE_NEGOTIATION_ENABLED_LOGIC_MATCH: `${appV2EventPrefix} Rate negotiation enabled logic match`,

  // Account deletion
  REQUEST_ACCOUNT_DELETION_LOGOUT: `${appV2EventPrefix} Log Out After Request Account Deletion`,
  REQUEST_ACCOUNT_DELETION_CONFIRM: `${appV2EventPrefix} Confirm Request Account Deletion`,
  REQUEST_ACCOUNT_DELETION_SEND_OTP: `${appV2EventPrefix} Request OTP For Account Deletion`,
  REQUEST_ACCOUNT_DELETION_CANCEL_AT_OTP: `${appV2EventPrefix} Cancel Request Account Deletion At OTP`,
  REQUEST_ACCOUNT_DELETION_CANCEL_AT_CONFIRM: `${appV2EventPrefix} Cancel Request Account Deletion At Confirmation`,

  // Agreement
  SIGN_AGREEMENT_VIEWED: `${appV2EventPrefix} Viewed sign agreement page`,

  // Sign new agreement
  SIGN_NEW_AGREEMENT_SEND_OTP: `${appV2EventPrefix} Request OTP for sign new agreement`,
  SIGN_NEW_AGREEMENT_STARTED: `${appV2EventPrefix} Sign new agreement`,
  SIGN_NEW_AGREEMENT_MODAL_DISMISS: `${appV2EventPrefix} Dismissed sign new agreement modal`,
  SIGN_NEW_AGREEMENT_MODAL_CONTINUE: `${appV2EventPrefix} Clicked continue on sign new agreement modal`,

  // Attendance Score
  ATTENDANCE_SCORE_PAGE_OPENED_FROM_FEEDBACK_MODAL: `${appV2EventPrefix} Attendance Score Page Opened From Feedback Modal`,
  SWITCH_ATTENDANCE_SCORE_TABS: `${appV2EventPrefix} Switch Attendance Score Tabs`,

  // Debit Card Expiration Banner
  TAPPED_DEBIT_CARD_ABOUT_TO_EXPIRE_BANNER: `${appV2EventPrefix} Tapped Debit Card About To Expire Banner`,
  TAPPED_DEBIT_CARD_EXPIRED_BANNER: `${appV2EventPrefix} Tapped Debit Card Expired Banner`,

  // A-Team
  OPENED_A_TEAM_FACILITY_PROFILE: `${appV2EventPrefix} Navigated to A-Team facility profile`,

  // Career Agent
  CAREER_AGENT_PAGE_VIEWED: `${appV2EventPrefix} Career Agent Page Viewed`,

  FEEDBACK_ON_HCP_WORK_QUALITY: `${appV2EventPrefix} Work quality feedback`,
  SIGNING_FLOW_V2_SIGNER_NAME_SUBMITTED: `${appV2EventPrefix} Signing flow V2 signer name submitted`,

  // Facility Timeclock Compliance
  FACILITY_TIMECLOCK_COMPLIANCE_EXPERIMENT_EVENT: `${appV2EventPrefix} Facility Timeclock Compliance Experiment Event`,
  FACILITY_TIMECLOCK_COMPLIANCE_EVENT: `${appV2EventPrefix} Facility Timeclock Compliance Event`,
} as const;

export const APP_V2_APP_EVENTS = {
  // Onboarding
  STRIPE_ONBOARDING_ERROR: `${appV2EventPrefix} Stripe Onboarding Error`,
  AGREEMENT_SIGNING_ERROR: `${appV2EventPrefix} Agreement Signing Error`,
  GET_WORKER_ONBOARDING_FAILURE: `${appV2EventPrefix} Failed to get worker onboarding`,
  PATCH_WORKER_ONBOARDING_FAILURE: `${appV2EventPrefix} Failed to update worker onboarding`,

  // App
  APPLICATION_CRASHED: `${appV2EventPrefix} Application Crashed`,
  FIREBASE_OTP_SIGN_IN_ERROR: `${appV2EventPrefix} Firebase signInWithCustomToken error`,
  AGENT_PROFILE_LOGGED_IN_ERROR: `${appV2EventPrefix} API request to /agentProfile/loggedIn failed`,
  APP_INFO_ERROR: `${appV2EventPrefix} Failed to load Capacitor App info`,
  DEVICE_INFO_ERROR: `${appV2EventPrefix} Failed to load Capacitor Device info`,
  API_SCHEMA_VALIDATION_ERROR: `${appV2EventPrefix} API Zod Schema validation error`,
  FEATURE_FLAG_SCHEMA_VALIDATION_ERROR: `${appV2EventPrefix} Feature Flag Zod schema validation error`,
  INVALID_SET_OF_COORDINATES_ERROR: `${appV2EventPrefix} Invalid set of coordinates error`,
  FAILED_TO_SET_UP_APP_STATE_LISTENER: `${appV2EventPrefix} Failed to set up app state listener`,
  APP_RESTORED: `${appV2EventPrefix} App Restored`,
  NON_EXISTING_ICON_REQUESTED: `${appV2EventPrefix} Non existing icon requested`,
  NON_EXISTING_ILLUSTRATION_REQUESTED: `${appV2EventPrefix} Non existing illustration requested`,
  FAILED_TO_OPEN_OS_STORE: `${appV2EventPrefix} Failed to open OS store`,
  SHOWING_FORCE_UPDATE_OR_NUDGE_DIALOG: `${appV2EventPrefix} Showing force update/update nudge dialog`,

  // Auth
  REQUEST_OTP_FAILURE: `${appV2EventPrefix} Failed to request OTP`,
  VALIDATE_OTP_FAILURE: `${appV2EventPrefix} Failed to validate OTP`,
  AUTH_ON_SUBMIT_FAILURE: `${appV2EventPrefix} Failed to initiate authentication during onSubmit`,
  REQUEST_LOGIN_LINK_FAILURE: `${appV2EventPrefix} Failed to request login link`,
  SIGNED_OUT_FROM_FIREBASE: `${appV2EventPrefix} Signed out from Firebase`,
  SIGNED_IN_TO_FIREBASE: `${appV2EventPrefix} Signed in to Firebase`,
  AUTHORIZED_WORKER: `${appV2EventPrefix} Authorized worker`,
  FIREBASE_TOKEN_LISTENER_ATTACHED_AFTER_USER_WAS_AUTHENTICATED: `${appV2EventPrefix} Firebase token listener attached after user was authenticated`,
  FIREBASE_ID_TOKEN_CHANGED: `${appV2EventPrefix} Firebase ID token changed`,
  SPLASH_SCREEN_HIDING_SKIPPED_DUE_TO_AUTH_STATE: `${appV2EventPrefix} Hiding of splash screen skipped because auth state has not loaded`,
  WORKER_INITIALIZATION_SKIPPED: `${appV2EventPrefix} Worker initialization skipped`,
  SPLASH_SCREEN_HIDE_TIMEOUT_SET: `${appV2EventPrefix} Set a timeout to hide splash screen because auth state has loaded`,
  SPLASH_SCREEN_HIDDEN_DUE_TO_AUTH_STATE: `${appV2EventPrefix} Hiding splash screen because auth state has loaded`,
  SESSION_POPULATED_AFTER_LOGIN: `${appV2EventPrefix} Session populated after login`,
  SESSION_POPULATE_AFTER_LOGIN_SKIPPED: `${appV2EventPrefix} Session populate after login skipped`,

  // Agent
  GET_WORKER_FAILURE: `${appV2EventPrefix} Failed to load agent profile`,
  UPDATE_AGENT_PROFILE_FAILURE: `${appV2EventPrefix} Failed to update agent profile`,
  SAVE_AGENT_PREFERENCES_SUCCESS: `${appV2EventPrefix} Saved agent preferences`,
  SAVE_AGENT_PREFERENCES_FAILURE: `${appV2EventPrefix} Failed to load agent preferences`,
  GET_AGENT_PROFILE_PICTURE_FAILURE: `${appV2EventPrefix} Failed to get agent profile picture`,
  GET_AGENT_BY_PHONE_FAILURE: `${appV2EventPrefix} Failed to load agent by phone`,
  POST_AGENT_LOGGED_IN_FAILURE: `${appV2EventPrefix} Failed to save agent logged in`,
  GET_WORKER_SUCCESS: `${appV2EventPrefix} Successfully fetched worker`,
  WORKER_INITIALIZATION_FAILURE: `${appV2EventPrefix} Failed to initialize worker`,
  GET_AGENT_SENT_HOME_CANCELLATION_PAY_PARAMS_FAILURE: `${appV2EventPrefix} Failed to load agent sent home cancellation pay params`,
  GET_AGENT_FRIENDS_FAILURE: `${appV2EventPrefix} Failed to load agent friends`,
  GET_AGENT_FRIEND_REQUESTS_FAILURE: `${appV2EventPrefix} Failed to load agent friend requests`,
  GET_RECENT_COLLEAGUES_FAILURE: `${appV2EventPrefix} Failed to load agent friend requests`,
  GET_SENT_HOME_REQUESTS_FAILURE: `${appV2EventPrefix} Failed to load sent home requests`,
  CREATE_SENT_HOME_REQUESTS_FAILURE: `${appV2EventPrefix} Failed to create sent home requests`,
  GET_OPEN_NEGOTIATIONS_COUNT_FAILURE: `${appV2EventPrefix} Failed to load open negotiations count`,
  GET_RATE_NEGOTIATIONS_LIST_FAILURE: `${appV2EventPrefix} Failed to load rate negotiations list`,
  GET_NEGOTIATION_SHIFT_DETAILS_FAILURE: `${appV2EventPrefix} Failed to load negotiation shift details`,
  CREATE_NEGOTIATION_FAILURE: `${appV2EventPrefix} Failed to create negotiation`,
  GET_INSTANT_PAY_STATUS_FAILURE: `${appV2EventPrefix} Failed to load instant pay status`,
  GET_SHIFT_SLOTS_V2_FAILURE: `${appV2EventPrefix} Failed to get worker shift slots`,

  // Booking Requests
  GET_BOOKING_REQUESTS_FAILURE: `${appV2EventPrefix} Failed to load booking requests`,
  DELETE_BOOKING_REQUEST_FAILURE: `${appV2EventPrefix} Failed to delete booking request`,
  POST_BOOKING_REQUEST_FAILURE: `${appV2EventPrefix} Failed to create booking request`,

  // Payroll
  GET_WORKER_PAYROLL_FAILURE: `${appV2EventPrefix} Failed to load worker payroll`,

  UPDATE_NON_EXISTENT_WORKER: `${appV2EventPrefix} Failed to update worker as it was not fetched yet`,

  // Streaks
  GET_AGENT_STREAKS_FAILURE: `${appV2EventPrefix} Failed to load agent streaks`,
  GET_AGENT_STREAKS_PROGRESS_FAILURE: `${appV2EventPrefix} Failed to load agent streaks progress`,
  GET_AGENT_STREAKS_BONUS_FAILURE: `${appV2EventPrefix} Failed to load agent streaks bonus`,
  GET_FACILITY_STREAKS_SETTINGS_FAILURE: `${appV2EventPrefix} Failed to load facility streaks settings`,

  // Facility
  GET_FACILITY_PROFILE_FAILURE: `${appV2EventPrefix} Failed to load facility profile`,
  GET_FACILITY_PHOTOS_FAILURE: `${appV2EventPrefix} Failed to load facility photos`,
  GET_FACILITY_RATINGS_FAILURE: `${appV2EventPrefix} Failed to load facility ratings`,
  GET_FACILITY_STREET_IMAGE_URL_FAILURE: `${appV2EventPrefix} Failed to load facility Google Maps street image URL`,
  GET_FACILITY_VERIFICATION_PREFERENCES_FAILURE: `${appV2EventPrefix} Failed to load facility verification preferences`,
  GET_SHIFT_SLOTS_FAILURE: `${appV2EventPrefix} Failed to get shift slots`,
  GET_FACILITY_EXCLUSION_FAILURE: `${appV2EventPrefix} Failed to load facility exclusion`,
  GET_WORKPLACES_FAILURE: `${appV2EventPrefix} Failed to load workplaces`,
  GET_WORKPLACE_PROFILE_FAILURE: `${appV2EventPrefix} Failed to load workplace profile`,

  // WorkplaceQuiz
  GET_WORKPLACE_QUIZ_FAILURE: `${appV2EventPrefix} Failed to load workplace quiz`,
  GET_WORKPLACE_QUIZ_RESULT_LOGS_FAILURE: `${appV2EventPrefix} Failed to load workplace quiz result logs`,
  SUBMIT_WORKPLACE_QUIZ_FAILURE: `${appV2EventPrefix} Failed to submit workplace quiz`,
  // This is temporary added event for debugging operationally if the quiz feature flag evaluation fails
  UNDEFINED_FACILITY_WHILE_EVALUATING_IS_QUIZ_ENABLED: `${appV2EventPrefix} Undefined facility while evaluating is quiz enabled`,

  // Skills Assessment
  GET_SKILLS_ASSESSMENTS_FAILURE: `${appV2EventPrefix} Failed to load skills assessments`,
  SKILLS_ASSESSMENTS_EVENT_FAILURE: `${appV2EventPrefix} Failed to create skills assessment event`,
  SKILLS_ASSESSMENTS_SCHEDULED_EVENT_FAILURE: `${appV2EventPrefix} Failed to create skills assessment SCHEDULED event`,
  SKILLS_ASSESSMENTS_STARTED_EVENT_FAILURE: `${appV2EventPrefix} Failed to create skills assessment STARTED event`,
  SKILLS_ASSESSMENTS_SUBMITTED_EVENT_FAILURE: `${appV2EventPrefix} Failed to create skills assessment SUBMITTED event`,
  SKILLS_ASSESSMENTS_IFRAME_ERROR: `${appV2EventPrefix} Error in Skills Assessment VideoAsk iframe`,
  SKILLS_ASSESSMENTS_NOTIFICATION_REQUEST_FAILURE: `${appV2EventPrefix} Failed to request skills assessment notification`,
  SKILLS_ASSESSMENTS_CONFIRMED_NOTIFICATION_ISSUE_FAILURE: `${appV2EventPrefix} Failed to confirm skills assessment notification issue`,

  // Facility specs
  // TODO(clipboardhealth.atlassian.net/browse/TX-761): Remove const usage after the complete rollout and flag removal
  GET_FACILITY_SOLVE_UNPAID_WORKED_BREAKS_STAGE: `${appV2EventPrefix} Failed to load facility SolveWorkedUnpaidBreaks stage`,

  // Facility notes
  GET_MANDATORY_BREAK_POLICY_ACKNOWLEDGEMENT_FAILURE: `${appV2EventPrefix} Failed to load mandatory break policy acknowledgement`,
  POST_MANDATORY_BREAK_POLICY_ACKNOWLEDGEMENT_FAILURE: `${appV2EventPrefix} Failed to post mandatory break policy acknowledgement`,
  GET_FACILITY_NOTES_FAILURE: `${appV2EventPrefix} Failed to load facility notes`,

  // Documents
  GET_DISTRIBUTION_URL_FAILURE: `${appV2EventPrefix} Failed to get document url`,
  CONSTRUCT_PDF_FROM_IMAGES_FAILURE: `${appV2EventPrefix} Failed to get create pdf from images`,
  PRESIGNED_URL_UPLOAD_FAILURE: `${appV2EventPrefix} Failed to get presigned url`,
  DOCUMENT_UPLOAD_CAMERA_TAKE_PHOTO_FAILURE: `${appV2EventPrefix} Failed to take photo with camera`,
  DOCUMENT_UPLOAD_CAMERA_TAKE_PHOTO_PERMISSION_DENIED: `${appV2EventPrefix} Permission denied for camera in document upload`,
  DOCUMENT_UPLOAD_MOBILE_FILE_PICKER_FAILURE: `${appV2EventPrefix} Failed to choose file on mobile`,
  DOCUMENT_UPLOAD_BROWSER_FILE_PICKER_FAILURE: `${appV2EventPrefix} Failed to choose file on browser`,
  GET_REJECTED_DOCUMENT_NOTIFICATION_FAILURE: `${appV2EventPrefix} Failed to get rejected document notification`,
  GET_DOCUMENT_ID_FAILURE: `${appV2EventPrefix} Failed to get document id`,
  GET_REQUIREMENT_EXPIRY_FAILURE: `${appV2EventPrefix} Failed to get requirement expiry`,
  CREATE_STRIPE_VERIFICATION_SESSION_FAILURE: `${appV2EventPrefix} Failed to create stripe identity verification session`,
  STRIPE_IDENTITY_VERIFICATION_SESSION_FAILURE: `${appV2EventPrefix} Error during stripe identity session`,
  STRIPE_IDENTITY_FLOW_BEGUN: `${appV2EventPrefix} Stripe Identity Flow Begun`,
  STRIPE_IDENTITY_FLOW_COMPLETED: `${appV2EventPrefix} Stripe Identity Flow Completed`,
  DELETE_DOCUMENT_FAILURE: `${appV2EventPrefix} Error while trying to delete document`,
  CREATE_DOCUMENT_FAILURE: `${appV2EventPrefix} Error while trying to create document`,
  CREATE_SHIFT_SIGNING_OUT_FEEDBACK_FAILURE: `${appV2EventPrefix} Error while trying to create shift signing out feedback`,
  DELETE_DOCUMENT_AFTER_STRIPE_IDENTITY_FAILURE: `${appV2EventPrefix} Error while trying to delete document after stripe failure`,
  STRIPE_IDENTITY_SDK_SESSION_EVENT: `${appV2EventPrefix} Stripe Identity Native SDK Event`,
  STRIPE_IDENTITY_SDK_SESSION_RESULT: `${appV2EventPrefix} Stripe Identity Native SDK Result`,
  MISSING_DOCUMENTS_ALERT_NOTIFY_SUPPORT_FAILURE: `${appV2EventPrefix} Failed to notify support from missing documents alerts`,
  REQUIREMENT_TYPE_DOCUMENTS_COUNT_EVENT: `${appV2EventPrefix} Requirement type documents count event`,
  REQUIREMENT_UNLOCKED_SHIFTS_TRACK_EVENT: `${appV2EventPrefix} Requirement unlocked shifts track event`,
  CREATE_HELLO_SIGN_SESSION_FAILURE: `${appV2EventPrefix} Failed to create hello sign session`,
  UPDATE_HELLO_SIGN_SESSION_FAILURE: `${appV2EventPrefix} Failed to update hello sign session`,
  GET_HELLO_SIGN_SESSION_FAILURE: `${appV2EventPrefix} Failed to get hello sign session`,
  INVALID_COMPOSITE_CONFIGURATION: `${appV2EventPrefix} Invalid composite configuration`,
  DOCUMENT_UPLOAD_MODAL_OPEN_TIME: `${appV2EventPrefix} Document uploading time`,
  HELLO_SIGN_SESSION_STARTED: `${appV2EventPrefix} Hello Sign Session Started`,
  HELLO_SIGN_SIGN_EVENT: `${appV2EventPrefix} Hello Sign Sign Event`,
  HELLO_SIGN_ERROR_EVENT: `${appV2EventPrefix} Hello Sign Error Event`,
  HELLO_SIGN_CANCEL_EVENT: `${appV2EventPrefix} Hello Sign Cancel Event`,
  HELLO_SIGN_SESSION_FALLBACK_TO_POLLING: `${appV2EventPrefix} Hello Sign Session Fallback to Polling`,
  DOCUMENT_DETAILS_PAGE_NOT_FOUND: `${appV2EventPrefix} Document Details Page Not Found`,
  DOCUMENTS_PAGE_QUALIFICATION_DROPDOWN_CHANGE: `${appV2EventPrefix} Documents Page Qualification Dropdown Change`,
  DOCUMENTS_PAGE_QUALIFICATION_DROPDOWN_SOURCE: `${appV2EventPrefix} Documents Page Qualification Dropdown Source`,
  GET_WORKER_REQUIREMENTS_FAILURE: `${appV2EventPrefix} Failed to load worker requirements`,

  // Open Shifts
  GET_OPEN_SHIFT_COUNT_FAILURE: `${appV2EventPrefix} Failed to load open shift count`,
  GET_WORKPLACE_OPEN_SHIFT_COUNT_FAILURE: `${appV2EventPrefix} Failed to load workplace open shift count`,
  GET_OPEN_SHIFT_BY_DATE_FAILURE: `${appV2EventPrefix} Failed to load open shift by date`,
  GET_OPEN_SHIFT_MODAL_FAILURE: `${appV2EventPrefix} Failed to load open shift modal`,
  GET_USER_TIMEZONE_FAILURE: `${appV2EventPrefix} Failed to load user timezone`,
  GET_AGENT_SHIFTS_FAILURE: `${appV2EventPrefix} Failed to load agent shifts`,
  WORKER_CALENDAR_STATS: `${appV2EventPrefix} Worker Calendar Stats`,
  GET_MAP_VIEW_FACILITY_OPEN_SHIFT_COUNT_FAILURE: `${appV2EventPrefix} Failed to load map view facility open shift count`,
  GET_FACILITY_VIEW_OPEN_SHIFTS_FAILURE: `${appV2EventPrefix} Failed to load facility view open shifts`,
  GET_LIST_VIEW_OPEN_SHIFTS_FAILURE: `${appV2EventPrefix} Failed to load open shifts for list view`,
  GET_SEARCH_SHIFTS_FAILURE: `${appV2EventPrefix} Failed to load search shifts`,

  // Shift Actions
  POST_CLAIM_SHIFT_FAILURE: `${appV2EventPrefix} Failed to claim shift`,
  POST_MARK_INTEREST_SHIFT_FAILURE: `${appV2EventPrefix} Failed to mark interest for shift`,
  POST_ACCEPT_NEGOTIATION_FAILURE: `${appV2EventPrefix} Failed to accept negotiation`,

  // Shift
  GET_SHIFT_STATE_FAILURE: `${appV2EventPrefix} Failed to load shift state`,
  GET_APP_CONFIGURATION_FAILURE: `${appV2EventPrefix} Failed to load shift configuration`,
  UPLOAD_SHIFT_TIMECARD_TO_S3_FAILURE: `${appV2EventPrefix} Failed to upload timecard to AWS S3`,
  UPDATE_SHIFT_TIMECARD_FAILURE: `${appV2EventPrefix} Failed to update shift timecard`,
  CANCEL_SHIFT_FAILURE: `${appV2EventPrefix} Failed to cancel shift`,
  GET_SHIFT_FAILURE: `${appV2EventPrefix} Failed to get shift`,
  GET_SHIFT_FEEDBACK_FAILURE: `${appV2EventPrefix} Failed to get shift feedback`,
  GET_UPCOMING_SHIFTS_FAILURE: `${appV2EventPrefix} Failed to get upcoming shifts for agent`,

  // MyShifts
  GET_NEXT_TWO_DAYS_SHIFTS_FAILURE: `${appV2EventPrefix} Failed to load next two days shifts`,
  GET_FUTURE_SHIFTS_FAILURE: `${appV2EventPrefix} Failed to load future shifts`,

  // Unverified shifts
  GET_UNVERIFIED_SHIFTS_FAILURE: `${appV2EventPrefix} Failed to load unverified shifts`,
  TIMESHEET_PHOTO_UPLOADED: `${appV2EventPrefix} Timesheet Photo Uploaded`,
  CANCEL_TIMESHEET_PHOTO_FORGOTTEN: `${appV2EventPrefix} Timesheet Photo Forgotten`,
  CANCEL_TIMECARD_SENT_ANOTHER_WAY: `${appV2EventPrefix} Timecard Sent Another Way`,
  CANCEL_SHIFT_NOT_ATTENDED: `${appV2EventPrefix} Shift Not Attended`,
  SET_TIMESHEET_AVAILABILITY_FAILURE: `${appV2EventPrefix} Failed to set timesheet availability`,
  UNVERIFIED_SHIFT_ACTION: `${appV2EventPrefix} Unverified Shift Action`,

  // Shift invites
  GET_PENDING_SHIFT_INVITES: `${appV2EventPrefix} Failed to load pending shift invites`,
  ACCEPT_OR_DECLINE_SHIFT_INVITE: `${appV2EventPrefix} Failed to accept/decline shift invite`,
  ACCEPT_OVERWORKING_SHIFT_INVITE: `${appV2EventPrefix} Accepting overworking shift invite`,
  SHIFT_INVITE_SCHEMA_VALIDATION_ERROR: `${appV2EventPrefix} API Zod Schema validation error`,
  SHIFT_INVITE_BANNER_CLICK: `${appV2EventPrefix} Shift Invite banner click`,
  SHIFT_INVITE_PENDING_COUNT_CLICK: `${appV2EventPrefix} Shift Invite pending count click`,
  SHIFT_INVITE_ERROR: `${appV2EventPrefix} Shift Invite error`,
  SHIFT_INVITE_CONFLICTING_SHIFT_FOUND: `${appV2EventPrefix} Shift Invite conflicting shift found`,

  // GCP
  POST_SIGN_URL_FAILURE: `${appV2EventPrefix} Failed to sign url for GCP`,

  // Google Places Autocomplete
  GET_ADDRESS_INCOMPLETE_DATA_FAILURE: `${appV2EventPrefix} Failed to get complete address from Places Autocomplete`,

  // Reviews
  GET_REVIEW_QUESTIONNAIRE_FAILURE: `${appV2EventPrefix} Failed to load review questions`,
  POST_REVIEW_FAILURE: `${appV2EventPrefix} Failed to post the review`,
  POST_COMMENT_FAILURE: `${appV2EventPrefix} Failed to post the comment`,
  PATCH_COMMENT_FAILURE: `${appV2EventPrefix} Failed to patch the comment`,
  POST_RATING_FAILURE: `${appV2EventPrefix} Failed to post the rating`,
  GET_RATING_AGGREGATES_FAILURE: `${appV2EventPrefix} Failed to get rating aggregates`,
  GET_MULTIPLE_CHOICE_AGGREGATES_FAILURE: `${appV2EventPrefix} Failed to get multiple choice aggregates`,
  GET_THREAD_AGGREGATES_FAILURE: `${appV2EventPrefix} Failed to get thread aggregates`,
  GET_REVIEW_COMMENTS_FAILURE: `${appV2EventPrefix} Failed to get review comments`,
  POST_COMMENT_REACTION: `${appV2EventPrefix} Failed to post comment reaction`,
  DELETE_COMMENT_REACTION: `${appV2EventPrefix} Failed to delete comment reaction`,
  GET_WORKPLACE_WORKERS_SHIFTS_FAILURE: `${appV2EventPrefix} Failed to get workplace worker shifts`,
  REVIEWS_TAG_SCREEN_ERROR: `${appV2EventPrefix} Failed to tag reviews screen`,
  GET_REVIEW_COMMENT_FAILURE: `${appV2EventPrefix} Failed to get review comments`,

  // Worker
  UPDATE_AGENT_DATA_FAILURE: `${appV2EventPrefix} Failed to update agent data`,

  // Worker Availability
  GET_AVAILABILITIES_API_FAILURE: `${appV2EventPrefix} Failed to load availabilities`,
  PUT_AVAILABILITIES_API_FAILURE: `${appV2EventPrefix} Failed to save availabilities`,

  // Requirement Status
  GET_REQUIREMENT_STATUS_FAILURE: `${appV2EventPrefix} Failed to load requirement status`,

  // Checkr Application
  GET_CHECKR_APPLICATION_STATUS_FAILURE: `${appV2EventPrefix} Failed to load checkr application status`,
  RESEND_CHECKR_INVITATION_FAILURE: `${appV2EventPrefix} Failed to resend checkr invitation`,
  SEND_ONBOARDING_CHECKR_INVITATION_FAILURE: `${appV2EventPrefix} Failed to send onboarding checkr invite`,

  // Documents
  GET_FILTERED_DOCUMENTS_FAILURE: `${appV2EventPrefix} Failed to load filtered documents`,
  GET_MISSING_REQUIREMENTS_BY_DATE_FAILURE: `${appV2EventPrefix} Failed to load missing requirements by date`,

  // Professional References
  GET_PROFESSIONAL_REFERENCES_FAILURE: `${appV2EventPrefix} Failed to load professional references`,

  // Workplace Referrals
  GET_WORKPLACE_REFERRAL_STATS_FAILURE: `${appV2EventPrefix} Failed to load workplace referral stats`,
  GET_WORKPLACE_REFERRALS_FAILURE: `${appV2EventPrefix} Failed to load workplace referrals`,

  // Worker to Worker Referrals
  GET_WORKER_REFERRAL_BONUSES_FAILURE: `${appV2EventPrefix} Failed to load worker to worker referral bonuses`,
  GET_WORKER_REFERRAL_PROFILE_FAILURE: `${appV2EventPrefix} Failed to load worker referral profile`,
  SHARE_REFERRAL_CODE_ERROR: `${appV2EventPrefix} Failed to complete sharing worker to worker referral code`,

  // WorkerReferrals
  POST_SHARE_YOUR_PROFILE_FAILURE: `${appV2EventPrefix} Failed to share your profile`,
  GET_WORKER_REFERRAL_CODE_FAILURE: `${appV2EventPrefix} Failed to get worker referral code`,
  POST_WORKER_REFERRAL_CODE_FAILURE: `${appV2EventPrefix} Failed to create worker referral code`,

  GET_AGENT_STATS_FAILURE: `${appV2EventPrefix} Failed to get agent stats`,

  // Work With Friends
  GET_WORKER_FRIEND_LINK_FAILURE: `${appV2EventPrefix} Failed to load worker friend link`,
  GET_WORKER_FRIEND_SETTINGS_FAILURE: `${appV2EventPrefix} Failed to load worker friend settings`,
  GET_INVITE_FRIEND_LINK_FAILURE: `${appV2EventPrefix} Failed to load invite friend link`,
  // Urgent Shifts
  GET_URGENT_SHIFTS_FAILURE: `${appV2EventPrefix} Failed to load urgent shifts`,
  GET_CHECK_ATTENDANCE_V1_FAILURE: `${appV2EventPrefix} Failed to check attendance v1`,
  POST_CONFIRM_ATTENDANCE_FAILURE: `${appV2EventPrefix} Failed to confirm attendance`,
  UPDATE_YOUR_ETA_FAILURE: `${appV2EventPrefix} Failed to update the ETA for shift`,

  // Referrals
  GET_REFERRALS_ELIGIBILITY_FAILURE: `${appV2EventPrefix} Failed to get referrals eligibility`,

  // Location
  GET_DISTANCE_BETWEEN_TWO_GEOLOCATIONS_FAILURE: `${appV2EventPrefix} Failed to get distance between two geolocations`,
  LOCATION_VERIFICATION_CHECK_FAILURE: `${appV2EventPrefix} Location Verification Check failed`,
  GET_DEVICE_GEOLOCATION_IF_ALLOWED_FAILURE: `${appV2EventPrefix} Get Device GeoLocation Failed`,
  IS_DEVICE_LOCATION_PERMISSION_GRANTED_FAILURE: `${appV2EventPrefix} Get Is Device Location Permission Granted Failed`,
  SKIP_ALWAYS_ALLOW_DEVICE_SETTING_CHECK: `${appV2EventPrefix} Get Is Device Location Permission Granted Failed`,
  TRACKING_MODE_CHANGE_FAILURE: `${appV2EventPrefix} Tracking Mode Change Failed`,
  HANDLE_COMMUTE_TOPIC_SUBSCRIPTION_FAILURE: `${appV2EventPrefix} Handle Commute Topic Subscription Failed`,
  FAILED_TO_ADD_IOS_SILENT_NOTIFICATION_LISTENER: `${appV2EventPrefix} Failed to add iOS silent notification listener`,
  HANDLE_SILENT_PUSH_NOTIFICATION_ERROR: `${appV2EventPrefix} Handle Silent Push Notification Failed`,
  TRIP_TRACKING_SILENT_NOTIFICATION_RECEIVED: `${appV2EventPrefix} Trip Tracking Silent Notification Received`,
  TRIP_TRACKING_SILENT_NOTIFICATION_DISCARDED: `${appV2EventPrefix} Trip Tracking Silent Notification Discarded`,
  TRIP_TRACKING_STOPPED: `${appV2EventPrefix} Trip Tracking Stopped`,
  TRIP_TRACKING_STARTED: `${appV2EventPrefix} Trip Tracking Started`,
  TRIP_TRACKING_ERROR: `${appV2EventPrefix} Trip Tracking Error`,
  TRIP_TRACKING_NOT_CALLED: `${appV2EventPrefix} Trip Tracking Not Called`,
  WORKER_LEFT_EARLY: `${appV2EventPrefix} Worker Left Early`,
  WORKER_DEPARTURE_UPDATE_FAILURE: `${appV2EventPrefix} Worker Departure Update Failure`,
  WORKER_UPDATED_LOCATION_SETTINGS: `${appV2EventPrefix} Worker Updated Location Settings`,
  LOCATION_ACCURACY_ERROR: `${appV2EventPrefix} Error while getting the location accuracy status`,
  HYPER_TRACK_TRIAL_ENABLED: `${appV2EventPrefix} HyperTrack Trial Enabled`,
  HYPER_TRACK_INIT_SUCCESS: `${appV2EventPrefix} HyperTrack Init Success`,
  HYPER_TRACK_INIT_FAILURE: `${appV2EventPrefix} HyperTrack Init Failure`,
  HYPER_TRACK_RESET_SUCCESS: `${appV2EventPrefix} HyperTrack Reset Success`,
  HYPER_TRACK_RESET_FAILURE: `${appV2EventPrefix} HyperTrack Reset Failure`,
  HYPER_TRACK_TRIP_TRACKING_STARTED: `${appV2EventPrefix} HyperTrack Trip Tracking Started`,
  HYPER_TRACK_TRIP_TRACKING_FAILED: `${appV2EventPrefix} HyperTrack Trip Tracking Failed`,
  HYPER_TRACK_GEOFENCE_CHECK: `${appV2EventPrefix} HyperTrack Geofence Check`,
  HYPER_TRACK_GEOFENCE_CHECK_SUCCEEDED: `${appV2EventPrefix} HyperTrack Geofence Check Succeeded`,
  HYPER_TRACK_GEOFENCE_CHECK_FAILED: `${appV2EventPrefix} HyperTrack Geofence Check Failed`,
  ERROR_FETCHING_PREVIOUS_LOCATION_PERMISSION: `${appV2EventPrefix} Error fetching previous location permission`,
  ERROR_SUBMITTING_SHIFT_TIME_SHEET: `${appV2EventPrefix} Error submitting shift time sheet`,
  URGENT_SHIFTS_UPDATE_LOCATION_ERROR: `${appV2EventPrefix} Location Permission Update Error`,

  // Chat
  ZENDESK_CHAT_OPENED: `${appV2EventPrefix} Opened Zendesk Chat`,
  GET_CHAT_CHANNELS_FAILURE: `${appV2EventPrefix} Failed to get chat channels`,

  // Shift bookability
  GET_BOOKABILITY_STATUS_FAILURE: `${appV2EventPrefix} Failed to load bookability status`,
  SHIFT_BLOCKED_CRITERIA_FAILURE: `${appV2EventPrefix} Shift Bookability Unmet Criteria Failure`,
  GET_PAGINATED_BOOKABILITY_STATUS_FAILURE: `${appV2EventPrefix} Failed to load paginated bookability status`,
  SHIFT_BLOCKED_DUE_TO_EMPTY_MISSING_DOCUMENTS: `${appV2EventPrefix} Shift blocked due to empty missing documents`,
  PRE_BOOKING_STATE_CHECKED: `${appV2EventPrefix} Pre Booking State Checked`,

  // Mandatory breaks
  GET_EXTRA_WORKED_TIME_REQUESTS_FAILURE: `${appV2EventPrefix} Failed to get extra worked time request`,

  // Zendesk
  ZENDESK_LOGIN_ERROR: `${appV2EventPrefix} Zendesk login error`,

  // Shift Invite
  SHIFT_INVITE_IN_CHAT: `${appV2EventPrefix} Shift Invite In Chat`,

  // Licenses
  GET_LICENSES_FAILURE: `${appV2EventPrefix} Failed to load licenses`,
  GET_LICENSES_COUNT_FAILURE: `${appV2EventPrefix} Failed to load licenses count`,
  GET_LICENSE_DETAILS_FAILURE: `${appV2EventPrefix} Failed to load license details`,

  // Shift Blocks
  GET_SHIFT_BLOCK_FAILURE: `${appV2EventPrefix} Failed to load shift block`,
  UPDATE_SHIFT_BLOCK_FAILURE: `${appV2EventPrefix} Failed to update shift block`,
  GET_WORKER_PREFERRED_STATUS_FAILURE: `${appV2EventPrefix} Failed to get worker's preferred status`,
  REQUEST_TO_BOOK_SHIFT_BLOCK_FAILURE: `${appV2EventPrefix} Failed to request to book shift block`,
  POST_CREATE_CHAT_CHANNEL_FAILURE: `${appV2EventPrefix} Failed to create chat channel`,

  // Qualifications
  GET_QUALIFICATIONS_FAILURE: `${appV2EventPrefix} Failed to get qualifications`,

  // Attendance score
  GET_ATTENDANCE_SCORE_HISTORY: `${appV2EventPrefix} Failed to load agent recent attendance score history`,
  GET_ATTENDANCE_SCORE_PROFILE: `${appV2EventPrefix} Failed to load agent attendance score profile`,

  // Account deletion
  REQUEST_ACCOUNT_DELETION_FAILURE: `${appV2EventPrefix} Failed to request account deletion`,
  CREATE_ACCOUNT_REACTIVATION_REQUEST_FAILURE: `${appV2EventPrefix} Failed to create account reactivation request`,
  EDIT_ACCOUNT_REACTIVATION_REQUEST_FAILURE: `${appV2EventPrefix} Failed to edit account reactivation request`,

  // Pdf viewer
  PDF_LIBRARY_SETUP_FAILURE: `${appV2EventPrefix} Failed to setup pdf.js library`,
  PDF_LOAD_FAILURE: `${appV2EventPrefix} Failed to load pdf`,
  PDF_LOAD_SUCCESS: `${appV2EventPrefix} Successfully loaded pdf`,
  PDF_DOWNLOAD_FAILURE: `${appV2EventPrefix} Failed to download pdf`,
  PDF_DOWNLOADED: `${appV2EventPrefix} Pdf downloaded`,
  PDF_PLATFORM_CHECK_FAILURE: `${appV2EventPrefix} Failed to check pdf platform`,
  PDF_PLATFORM_UNSUPPORTED_ONBOARDING: `${appV2EventPrefix} Pdf platform is unsupported at onboarding`,

  // NFC
  NFC_HASH_VALIDATION_REQUEST_FAILED: `${appV2EventPrefix} NFC hash validation request failed`,
  NFC_HASH_VALIDATION_REQUEST_SUCCEEDED: `${appV2EventPrefix} NFC hash validation request succeeded`,
  NFC_HASH_VALIDATION_ERROR: `${appV2EventPrefix} NFC hash validation error`,
  NFC_HASH_READ_FAILED: `${appV2EventPrefix} NFC hash read failed`,
  NFC_SCAN_WARNING: `${appV2EventPrefix} NFC scan warning`,
  NFC_SCAN_FAILED_UNEXPECTEDLY: `${appV2EventPrefix} NFC scan failed unexpectedly`,
  DISABLE_NFC_CHECK_FAILED: `${appV2EventPrefix} disable NFC check failed`,
  DISABLE_NFC_CHECK_ACCOUNT_LOG_FAILED: `${appV2EventPrefix} disable NFC check account log failed`,

  // Agreements
  GET_SIGNED_AGREEMENTS_FAILURE: `${appV2EventPrefix} Failed to get signed agreements`,
  GET_AGREEMENTS_FAILURE: `${appV2EventPrefix} Failed to get agreements`,

  // Sign new agreement
  SIGN_NEW_AGREEMENT_FAILURE: `${appV2EventPrefix} Failed to sign new agreement`,
  SIGN_NEW_AGREEMENT_SUCCESS: `${appV2EventPrefix} Successfully signed new agreement`,
  SIGN_NEW_AGREEMENT_MODAL_VIEW: `${appV2EventPrefix} Viewed sign new agreement modal`,

  // Proof of Earnings
  POST_SEND_PROOF_OF_EARNINGS_EMAIL_FAILURE: `${appV2EventPrefix} Failed to send proof of earnings email`,

  // Debit Card Expiration Status
  GET_EXTERNAL_PAYMENT_ACCOUNTS_FAILURE: `${appV2EventPrefix} Failed to get external payment accounts`,

  // Debug
  USER_SIGN_OUT_INTERRUPTED: `${appV2EventPrefix} User was automatically signing out, but auth state has changed`,
  SCREEN_ORIENTATION_LOCK_FAILED: `${appV2EventPrefix} Screen Orientation Lock Failed`,

  // Rankings
  GET_WORKER_RANKINGS_FAILURE: `${appV2EventPrefix} Failed to get worker rankings`,

  // Placement Candidate
  GET_PLACEMENT_CANDIDATE_FAILURE: `${appV2EventPrefix} Failed to get placement candidate`,
  CREATE_OR_GET_PLACEMENT_CANDIDATE_FAILURE: `${appV2EventPrefix} Failed to create or get placement candidate`,
  UPDATE_PLACEMENT_CANDIDATE_FAILURE: `${appV2EventPrefix} Failed to update placement candidate`,
  PLACEMENT_CANDIDATE_RESUME_UPLOAD_FAILURE: `${appV2EventPrefix} Failed to upload placement candidate resume`,
  PLACEMENT_CANDIDATE_RESUME_CREATE_FAILURE: `${appV2EventPrefix} Failed to create placement candidate resume`,
  PLACEMENT_CANDIDATE_PAGE_VIEWED: `${appV2EventPrefix} Placement candidate page viewed`,
  PLACEMENT_CANDIDATE_STATUS_UPDATED: `${appV2EventPrefix} Placement candidate status updated`,
  PLACEMENT_CANDIDATE_JOB_PREFERENCES_SUBMITTED: `${appV2EventPrefix} Placement candidate job preferences submitted`,
  PLACEMENT_CANDIDATE_VIDEOASK_SUBMITTED: `${appV2EventPrefix} Placement candidate videoask submitted`,
  PLACEMENT_CANDIDATE_VIDEOASK_VIA_BROWSER_CLICKED: `${appV2EventPrefix} Placement candidate videoask via browser clicked`,
  PLACEMENT_CANDIDATE_REQUIREMENTS_REUPLOAD_CLICKED: `${appV2EventPrefix} Placement candidate requirements reupload clicked`,
  PLACEMENT_CANDIDATE_RESUME_UPLOADED: `${appV2EventPrefix} Placement candidate resume uploaded`,
  PLACEMENT_CANDIDATE_CREATE_RESUME_CLICKED: `${appV2EventPrefix} Placement candidate create resume clicked`,
  PLACEMENT_CANDIDATE_CREATE_RESUME_FORM_DISCARDED: `${appV2EventPrefix} Placement candidate create resume form discarded`,
  PLACEMENT_CANDIDATE_RESUME_CREATED: `${appV2EventPrefix} Placement candidate resume created`,
  PLACEMENT_CANDIDATE_RESUME_DELETED: `${appV2EventPrefix} Placement candidate resume deleted`,
  PLACEMENT_CANDIDATE_PROFILE_UPDATE_CLICKED: `${appV2EventPrefix} Placement candidate profile update clicked`,
  PLACEMENT_CANDIDATE_CHAT_ACTION: `${appV2EventPrefix} Placement candidate chat action`,
  PLACEMENT_CANDIDATE_RESUME_UPLOAD_INVALID_FILE_TYPE: `${appV2EventPrefix} Placement candidate resume upload invalid file type`,
  PLACEMENT_MARKETING_LINK_CLICKED: `${appV2EventPrefix} Placement marketing link clicked`,
  PLACEMENT_WORK_TRIAL_SHIFT_BOOKED: `${appV2EventPrefix} Placement work trial shift booked`,

  // Placement
  GET_PLACEMENT_FAILURE: `${appV2EventPrefix} Failed to load placement`,
  GET_PLACEMENT_WORKPLACES_FAILURE: `${appV2EventPrefix} Failed to load placement workplaces`,
  GET_PLACEMENT_PAY_FAILURE: `${appV2EventPrefix} Failed to load pay transparency data`,
  PLACEMENT_APPLICATION_CREATE_FAILURE: `${appV2EventPrefix} Failed to create placement application`,

  PLACEMENTS_PAGE_VIEWED: `${appV2EventPrefix} Placements page viewed`,
  PLACEMENTS_PAGE_ACTIONS: `${appV2EventPrefix} Placements page actions`,
  PLACEMENT_DETAILS_PAGE_VIEWED: `${appV2EventPrefix} Placement details page viewed`,
  PLACEMENT_DETAILS_PAGE_ACTIONS: `${appV2EventPrefix} Placement details page actions`,
  PLACEMENT_APPLICATION_CREATED: `${appV2EventPrefix} Placement application created`,
  PLACEMENT_CANDIDATE_RESUME_DOWNLOAD_CLICKED: `${appV2EventPrefix} Placement candidate resume downloaded`,
  PLACEMENT_CHAT_PAGE_VIEWED: `${appV2EventPrefix} Placement chat page viewed`,
  // no longer used since the UI was removed
  PLACEMENT_PAY_BREAKDOWN_DESCRIPTION_CLICKED: `${appV2EventPrefix} Pay transparency breakdown description clicked`,
  PLACEMENT_PAY_VIEWED: `${appV2EventPrefix} Placement Pay viewed`,
  PLACEMENT_PAY_SURVEY_VIEWED: `${appV2EventPrefix} Pay survey viewed`,
  PLACEMENT_PAY_BREAKDOWN_VIEWED: `${appV2EventPrefix} Pay transparency breakdown viewed`,
  PLACEMENT_PAY_TRANSPARENCY_CALL_MODAL_OPENED: `${appV2EventPrefix} Pay transparency contribution call started modal viewed`,
  PLACEMENT_PAY_TRANSPARENCY_CALL_ISSUES: `${appV2EventPrefix} Pay transparency contribution call issue reported`,
  PLACEMENT_PAY_DATA_UPLOAD_STARTED: `${appV2EventPrefix} Pay data upload started`,
  PLACEMENT_PAY_DATA_UPLOAD_SUCCESS: `${appV2EventPrefix} Pay data upload success`,
  PLACEMENT_PAY_DATA_UPLOAD_FAILURE: `${appV2EventPrefix} Pay data upload failure`,
  PLACEMENT_PAY_SURVEY_RESPONSE_SUCCESS: `${appV2EventPrefix} Pay survey response success`,
  PLACEMENT_PAY_SURVEY_RESPONSE_FAILURE: `${appV2EventPrefix} Pay survey response failure`,
  HIRING_REFER_A_FRIEND_LINK_SHARED: `${appV2EventPrefix} Hiring refer a friend link shared`,
  PLACEMENT_ONBOARDING_BANNER_VIEWED: `${appV2EventPrefix} Placement onboarding banner viewed`,
  PLACEMENT_ONBOARDING_BANNER_CTA_CLICKED: `${appV2EventPrefix} Placement onboarding banner cta clicked`,
  PLACEMENT_ONBOARDING_PAGE_VIEWED: `${appV2EventPrefix} Placement onboarding page viewed`,
  PLACEMENT_ONBOARDING_PAGE_ACTIONS: `${appV2EventPrefix} Placement onboarding page actions`,

  // Referrals
  GET_REFERRALS_FAILURE: `${appV2EventPrefix} Failed to get referrals`,
  CREATE_REFERRALS_FAILURE: `${appV2EventPrefix} Failed to create a referral`,
  CREATE_PLACEMENT_DEEP_LINK_FAILURE: `${appV2EventPrefix} Failed to create a placement deep link`,
  PLACEMENT_INCENTIVE_BANNER_VIEWED: `${appV2EventPrefix} Placement incentive banner viewed`,
  PLACEMENT_INCENTIVE_BANNER_CTA_CLICKED: `${appV2EventPrefix} Placement incentive banner cta clicked`,
  PLACEMENT_GET_HIRED_AND_BONUS_SHEET_ACTION: `${appV2EventPrefix} Placement get hired and bonus sheet action`,
  PLACEMENT_REFER_A_FRIEND_SHEET_ACTION: `${appV2EventPrefix} Placement refer a friend sheet action`,
  PLACEMENT_REDEEM_CODE: `${appV2EventPrefix} Placement redeem code`,

  // On Call
  GET_ON_CALL_SHIFTS_FAILURE: `${appV2EventPrefix} Failed to load on call shifts`,
  END_ON_CALL_SHIFT_FAILURE: `${appV2EventPrefix} Failed to end on call shift`,
  ACCEPT_ON_CALL_SHIFT_OFFER_FAILURE: `${appV2EventPrefix} Failed to accept on call shift offer`,

  // Interview Availability
  GET_INTERVIEW_AVAILABILITY_FAILURE: `${appV2EventPrefix} Failed to load interview availability`,

  // Booked Interview
  BOOKED_INTERVIEW_CLICKED: `${appV2EventPrefix} Booked interview clicked`,

  // Update Interview
  UPDATE_INTERVIEW_SUCCESS: `${appV2EventPrefix} Successfully updated interview`,
  UPDATE_INTERVIEW_FAILURE: `${appV2EventPrefix} Failed to update interview`,

  // Google Maps SDK
  GOOGLE_MAPS_SDK_LOADED: `${appV2EventPrefix} Google Maps SDK Loaded`,
  GOOGLE_MAPS_SDK_LOAD_ERROR: `${appV2EventPrefix} Google Maps SDK Load Error`,

  // Push Notification Permission Sync
  PUSH_NOTIFICATION_PERMISSION_SYNC_FAILURE: `${appV2EventPrefix} Push Notification Permission Sync Failure`,
  PUSH_NOTIFICATION_PERMISSION_CHANGED: `${appV2EventPrefix} Push Notification Permission Changed`,

  // Facility Timeclock Compliance
  FACILITY_TIMECLOCK_COMPLIANCE_ERROR: `${appV2EventPrefix} Facility Timeclock Compliance Error`,

  // Shift Time Proposal
  SHIFT_TIME_PROPOSAL_ACCEPTED: `${appV2EventPrefix} Shift Time Proposal Accepted`,
  SHIFT_TIME_PROPOSAL_DECLINED: `${appV2EventPrefix} Shift Time Proposal Declined`,
  SHIFT_TIME_PROPOSAL_ACCEPTED_ERROR: `${appV2EventPrefix} Shift Time Proposal Accepted Error`,
  SHIFT_TIME_PROPOSAL_DECLINED_ERROR: `${appV2EventPrefix} Shift Time Proposal Declined Error`,

  // White Screen Detection
  WHITE_SCREEN_DETECTED: `${appV2EventPrefix} White Screen Detected`,
  WHITE_SCREEN_DETECTION_ERROR: `${appV2EventPrefix} White Screen Detection Error`,
} as const;
